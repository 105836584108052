.select {
  min-width: 150px;
}

.selectContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.filterContainer {
  height: 40px;
  width: 60px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img {
  width: 25px;
  height: 25px;
}
