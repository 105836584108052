.select {
  min-width: 150px;
}

.selectContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.root {
  background: #f4f5f6;
}

.downloadContainer {
  display: flex;
  justify-content: flex-end;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  background: #f4f5f6;
}
.filterContainer {
  height: 40px;
  width: 60px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dateContainer {
  display: flex;
}
