.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.mainBox {
  border: 1px solid rgb(244, 245, 246);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 10px;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.number {
  font-weight: 500;
  font-size: 20px;
}

.secondaryBox {
  border: 1px solid rgb(244, 245, 246);
  display: flex;
  flex-direction: column;
}

.domainBox {
  border: 1px solid rgb(244, 245, 246);
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.domain {
  font-size: 14px;
  color: #e8444f;
}

.domainUrl {
  font-size: 14px;
}

.domainTitle {
  font-weight: 500;
  margin: 10px;
}
