.container {
  display: flex;
  flex-direction: column;
}

.inp {
  height: 41px !important;
  font-size: 1rem !important;
  margin-top: 6px;
}

.urlRoot {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.urlContainer {
  display: flex;
  background-color: rgba(232, 68, 79, 0.5);
  padding: 6px 10px;
  border-radius: 10px;
  font-weight: 500;
  margin-top: 8px;
  margin-right: 8px;
  word-break: break-all;
}

.urlContainerEx {
  display: flex;
  /* background-color: rgba(232, 68, 79, 0.5); */
  padding: 6px 10px;
  border-radius: 10px;
  font-weight: 500;
  margin-top: 8px;
  margin-right: 8px;
  word-break: break-all;
  border: 1px solid rgba(232, 68, 79, 1);
}

.urlImg {
  height: 14px;
  width: 14px;
}

.urlImgContainer {
  margin-left: 6px;
  cursor: pointer;
}

.selectInp {
  height: 41px !important;
  margin-top: 6px;
}

.rangePicker {
  height: 38px;
  margin-top: 6px;
  width: 100%;
}
