.ant-slider-rail{
    background: #e8e8e8 !important;
}

.ant-slider:hover .ant-slider-rail {
    background: #d5d5d5 !important;
}

.ant-radio-group{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}