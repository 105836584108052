.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.durationContainer {
  display: flex;
  font-size: 14px;
}

.activateBox {
  height: 32px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

.nonActive {
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid #d9d9d9;
}

.active {
  color: #032e9b;
  border: 1px solid #032e9b;
}
