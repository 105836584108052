.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgb(244, 245, 246);
  flex-direction: column;
  height: 220px;
}
.titleContainer {
  margin-bottom: 1rem;
  font-weight: 500;
}

.spinMainStatsItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
