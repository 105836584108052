.appBarDropDownText {
  color: #000 !important;
}
.navBgColor {
  background-color: #333;
  border-bottom: 1px solid #dee2e6 !important;
}
.navBgColorAdmin {
  background-color: #333;
  border-bottom: 1px solid #dee2e6 !important;
}

.login-card {
  background-color: #fff !important;
}
.loginTitleColor {
  color: #3a404d;
}
.loginBtn {
  line-height: 1.2 !important;
  letter-spacing: 0 !important;
  font-weight: 600 !important;
  padding: 16px 26px !important;
  border: 1px solid #ff5252 !important;
  background: #ff5252 !important;
  color: #fff !important;
  border-radius: 2px !important;
  width: 100%;
}
.loginBtn:focus {
  outline: none !important;
}
.bgImgLoginAdmin {
  /* background-image: url("../src/views/Login/images/bgImgAdmin.jpg"); */
  /* background: red; fallback color */
  /* background: linear-gradient( to top, rgba(204, 28, 28, 0.055), rgb(21, 18, 189),violet,indigo); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  background: #333;
}
.bgImgLogin {
  height: 100vh;
  overflow: hidden;
  /* background-image: linear-gradient(180deg, #233857 0%, #3686b1 100%); */
  background-color: #333;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.btn.btn-success {
  background-color: #ff5252 !important;
  border-radius: 4px;
  border: solid #ff5252 1px;
}
.btn.btn-info {
  background-color: #ff5252 !important;
  border-radius: 4px;
  border: solid #ff5252 1px;
}
.btn.btn-primary {
  background-color: #ff5252 !important;
}
.pagination .page-item.active .page-link {
  background-color: #ff5252 !important;
}
.ant-slider-track {
  background-color: #ff5252 !important;
}
.ant-slider-handle {
  border: solid 2px #ff5252;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ff5252 !important;
}
.ant-radio-inner::after {
  background-color: #ff5252 !important;
}
.ant-pagination-item-active {
  border-color: #ff5252 !important;
}
#tabBar-link a {
  color: #ff5252 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff5252;
  border-color: #ff5252;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ff5252 !important;
  background-color: #ff5252 !important;
}

.text-primary {
  color: #ff5252 !important;
}

.primary {
  color: #ff5252 !important;
}

.primary:hover {
  color: #ff5252 !important;
}

.primaryBackground {
  background-color: #ff5252 !important;
}

.btnPrimary {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.btnPrimary:hover {
  background-color: #ff5252 !important;
}

.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #ff5252 !important;
}

.ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #ff5252 !important;
}

.ant-spin-dot-item {
  background: #ff5252 !important;
}
.navbar-nav .dropdown-toggle::after {
  color: #fff;
}

.navBarText {
  color: #fff;
}
