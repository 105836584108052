.container {
  display: flex;
  flex-direction: column;
}

.topSection {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  width: 100%;
  border: 1px solid rgb(244, 245, 246);
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.table {
  background-color: #fff;
  padding: 0px 10px;
  border: 1px solid rgb(244, 245, 246);
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.tableTitle {
  color: #59b4d3;
  font-weight: 500;
}

.domainUrl {
  color: #b8c0cb;
  font-size: 14px;
  font-weight: 500;
}
