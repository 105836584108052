.container {
  display: flex;
  flex-direction: column;
}

.select {
  width: 100% !important;
}

.inp {
  height: 38px !important;
  font-size: 1rem !important;
}
