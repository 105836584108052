.filterContainer {
  display: flex;
  flex-direction: column;
}

.filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
}

.option {
  margin: 10px 0px 0px 0px !important;
}

.heading {
  font-weight: 500 !important;
}
