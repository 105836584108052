.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.filterContainter {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  background: #f4f5f6;
}

.statsContainer {
  display: flex;
  flex-wrap: wrap;
}

.stats {
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(244, 245, 246);
  padding-top: 10px;
}

.chatLoading {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}
