.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(244, 245, 246);
  flex-direction: column;
  background-color: #fff;
  justify-content: space-between;
  padding: 15px 0px;
}

.subItem {
  display: flex;
  gap: 6px;
}

.title {
  font-weight: 500;
}

.amount {
  font-size: 20px;
  font-weight: 500;
}
