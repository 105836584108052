.bgColor {
  background-color: rgb(244, 245, 246);
  padding: 0px 33px;
  margin-right: -15px;
  margin-left: -15px;
}

.menuContainer {
  display: flex;
  height: 80px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  border: 1px solid rgb(244, 245, 246);
  background-color: #fff;
  flex: 1 1 0px;
}

.img {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.setup {
  margin-right: 30px;
}

.mb {
  margin-bottom: 20px;
}

.filtersHeader {
  /* padding: 20px; */
}
