.headContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
}

.segment {
  display: flex;
  flex-direction: column;
}
