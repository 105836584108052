.text-below{
    position: relative;
    display: inline-block;
    width: 40px;
    text-align: center;
}
.text-below sub{
    width: 100%;
    display: block;
    position: absolute;
}

ul.column-3{
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    
}