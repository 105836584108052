.select {
  width: 150px;
}

.selectContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}
